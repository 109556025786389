import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';

// Images
import logo from "../../images/logo.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClient: false,
      phone: '',
      email: '',
      loginSuccess: false,
      loginError: false,
      errorMessage: '',
      phoneError: '',
      login_otp: '',
      is_phone_verified: false,
      countdown: 10,
      disabledSendOTP: false,
    };
  }

  componentDidMount() {
    this.props.setShowChatBot(false);
    this.setState({ isClient: true });
    const phone = localStorage.getItem('userLogin');
    if (phone) {
      this.logInUser();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  logInUser = () => {
    const { phone, email } = this.state;
    localStorage.setItem('userLogin', phone);
    localStorage.setItem('userPhone', phone);
    localStorage.setItem('userEmail', email);
    this.props.setUser({ is_logged: true, ph: phone, email: email });
    this.props.history.push('/register', { partner_phone: phone });
  }

  updateCountdown = () => {
    if (this.state.countdown > 0) {
      this.setState(prevState => ({ countdown: prevState.countdown - 1 }));
    } else {
      clearInterval(this.interval);
      this.setState({ countdown: 10, disabledSendOTP: false });
    }
  };

  handlePhoneChange = (event) => {
    this.setState({ phone: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { is_phone_verified } = this.state;

    if (is_phone_verified) {
      this.logInUser();
    }
  }

  handleChangeLoginOTP = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		const params = {
			phone: this.state.phone,
			booking_otp: event.target.value
		}
		try {
			axios.post("https://ridenrepair.com/api/validate_booking_otp", params, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				if (res.status === 200) {
					this.setState({ is_phone_verified: res.data.is_valid });
				}
			});
		} catch (error) {
		}
	}

  validatePhone = () => {
		const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
		const isValidPhone = phoneRegex.test(this.state.phone);
		if (isValidPhone) {
			// phone is valid
			this.setState({ phoneError: '' });
		} else {
			// phone is invalid
			this.setState({ phoneError: 'Invalid Phone Number' });
		}
	}

	startVerification = (event) => {
    event.preventDefault();
		const tasks = [this.validatePhone];
		for (const fn of tasks) {
			fn();
		}
		if (this.state.phoneError !== '') {
			return;
		}

    this.setState({ disabledSendOTP: true });
    this.interval = setInterval(this.updateCountdown, 1000);

		
		const params = {
			phone: this.state.phone
		}

		axios.post("https://ridenrepair.com/api/send_booking_otp_sms", params);
		
		this.setState({ show_enter_otp_box: true });
	}

  render() {
    const { countdown, disabledSendOTP, isClient } = this.state;

    return (
      <>
        <div className="page-wraper account-wraper bg-gray py-100 px-20">
          <div className="my-account">
            <div className="logo">
              { isClient && <img src={logo} alt="" /> }
            </div>
            {this.state.loginSuccess && <div className="alert alert-success">Successfully logged in</div>}
            {this.state.loginError && <div className="alert alert-danger">{this.state.errorMessage}</div>}
            <form>
              <div className="form-group">
                <center><p>User Login</p></center>
              </div>
              <div className="form-group">
                <input type="number" className="form-control" placeholder="Phone" value={this.state.phone} onChange={this.handlePhoneChange} />
              </div>
              <div className="form-group">
                <input style={{ border: this.state.is_phone_verified ? '2px solid green' : '' }} type="number" className="form-control" name="login_otp" placeholder="Enter OTP" value={this.state.login_otp} onChange={this.handleChangeLoginOTP} />
                {/* {disabledSendOTP ? (
                  <div style={{ marginTop: '4%', color: 'gray' }}>
                    Request another one in {countdown} seconds...
                  </div>
                ) : (
                  <div style={{ 'cursor': 'pointer', 'text-decoration': 'underline', 'margin-top': '4%' }} onClick={this.startVerification}>
                    Send OTP
                  </div>
                )} */}
                <button
                  type="button"
                  className="btn btn-secondary mt-2"
                  onClick={this.startVerification}
                  disabled={disabledSendOTP}
                >
                  {disabledSendOTP
                    ? `Resend OTP in ${countdown} seconds`
                    : 'Send OTP'}
                </button>
                {this.state.phoneError && <div className="alert alert-danger">{this.state.phoneError}</div>}
              </div>
              <div className="form-group">
                <button className="btn mb-10 btn-lg btn-primary w-100" onClick={this.handleSubmit}>Login</button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Login);