// import React from 'react';
import '../../css/how-ride-n-repair-works.css';

// Replace these image imports with your real images:
import step1Img from '../../images/logo_ridenrepair.webp';
import step2Img from '../../images/logo_ridenrepair.webp';
import step3Img from '../../images/logo_ridenrepair.webp';
import step4Img from '../../images/logo_ridenrepair.webp';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg1.png";
import aboutPic2 from "../../images/about/optimized_aboutus.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class HowRideNRepairWorks extends Component {
  render() {
    const { isMobileApp } = this.props;
    
    return (
      <>
        <section
          className="section-area section-sp1 bg-white"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="heading-bx">
                <h2 className="title">HOW RIDE N REPAIR WORKS</h2>
                <p>
                  At Ride N Repair, we make your two-wheeler servicing simple, quick, and hassle-free. Here’s how it works:
                </p>

                <h4>1. Choose your Vehicle & Service</h4>
                <p>
                  Choose your vehicle brand and model, then explore a wide range of 
                  maintenance and repair services for your bike or car. From servicing 
                  and engine oil changes to jump starts, puncture fixes, and quick 
                  repairs – we've got you covered.
                </p>

                <h4>2. Doorstep Mechanic or Free Pickup</h4>
                <p>
                  Schedule our expert mechanics to visit your location or opt for a
                  free pickup. No more waiting at service stations – we come right
                  to your doorstep to ensure maximum convenience.
                </p>

                <h4>3. Real-Time Tracking & Instant Spare Parts</h4>
                <p>
                  Stay informed every step of the way. Track your vehicle’s service
                  progress in real time by downloading our app, and get instant spare 
                  parts delivery and replacement at your doorstep – with transparent 
                  updates on every repair.
                </p>

                <h4>4. Earn Rewards & Refer Friends</h4>
                <p>
                  After a final quality check, your vehicle is returned to you in
                  top shape. Share Ride N Repair with friends and family to earn
                  referral rewards – Rs. 500 to you and your friends!
                </p>
              </div>
              <div style = {{ textAlign: 'center' }} className="mt-15 mt-md-0">
                <Link to="/contact" className="btn btn-primary me-3">
                  Contact Us
                </Link>
                <Link
                  to={isMobileApp ? '/register' : '/booking'}
                  className="btn btn-outline-secondary"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HowRideNRepairWorks;
