import React, { Component } from 'react';
import '../../css/faq-section.css';  // Optional: your custom styling

// 1) The universal FAQs from above, with placeholders:
const UNIVERSAL_FAQS = [
  {
    question: "1) Why Choose Ride N Repair for Vehicle Repairs{LOCATION}?",
    answer: `
      We have a team of certified mechanics and a vast network of service points{LOCATION}.
      Our transparent pricing, genuine parts, and reliable service make us a preferred 
      choice for bike and car repair services.
    `
  },
  {
    question: "2) Comprehensive Bike Repair Services{LOCATION}",
    answer: `
      From engine diagnostics to oil changes, Ride N Repair offers a complete range 
      of two-wheeler services{LOCATION}. We handle all makes and models, ensuring 
      your bike performs at its best.
    `
  },
  {
    question: "3) Expert Car Repair Services{LOCATION}",
    answer: `
      Our one-stop solution covers all car repair needs, including brake repairs, 
      AC servicing, and tire replacements{LOCATION}. Our quick turnaround times 
      and genuine parts ensure that your car is back on the road in no time.
    `
  },
  {
    question: "4) Mobile Repair Services at Your Location{LOCATION}",
    answer: `
      Stuck in the middle of a busy road{LOCATION}? Our mobile repair team will come 
      to you and handle the issue on the spot, covering everything from North to South, 
      East to West.
    `
  },
  {
    question: "5) Spare Parts & Pricing{LOCATION}",
    answer: `
      We use genuine spare parts for all repairs{LOCATION}. With transparent pricing 
      and no hidden costs, you can rest assured you're getting the best value.
    `
  },
  {
    question: "6) Areas We Serve{LOCATION}",
    answer: `
      We cater to all parts of the city near you{LOCATION}. Wherever you are, Ride N Repair 
      is ready to offer prompt, reliable service right at your doorstep.
    `
  }
];

class FaqSection extends Component {
  // Helper method to replace {LOCATION} placeholder
  replaceLocationPlaceholders = (text, location) => {
    if (!location) {
      // If location is empty, remove the placeholder text entirely
      return text.replaceAll('{LOCATION}', '');
    }
    // If we have a location, replace the placeholder with location text
    return text.replaceAll('{LOCATION}', location);
  };

  render() {
    const { cityKey = '', areaKey = '', cityContent } = this.props;

    let locationText = '';

    if (cityKey) {
      // --- 1) Format the cityKey
      const formattedCityKey = cityKey
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      // Find matching area object in cityContent (if cityKey is valid)
      const areaObj = cityContent[cityKey]?.areas?.find(
        (area) => area.value === areaKey
      );
      // If found, we have a label for display
      const areaLabel = areaObj?.label || '';

      if (cityKey && areaLabel) {
        locationText = ` in ${areaLabel}, ${formattedCityKey}`;
      } else if (cityKey) {
        locationText = ` in ${formattedCityKey}`;
      }
    }

    return (
      <section className="faq-section">
        <div className="container">
          <div className="heading-bx text-center">
            {/* e.g. "FAQs in HSR Layout, Bangalore" if area & city exist */}
            <h2>FAQs{locationText}</h2>
          </div>

          <div className="faq-content">
            {UNIVERSAL_FAQS.map((faq, index) => {
              // Replace placeholders in both question & answer
              const finalQuestion = this.replaceLocationPlaceholders(
                faq.question.trim(),
                locationText
              );
              const finalAnswer = this.replaceLocationPlaceholders(
                faq.answer.trim(),
                locationText
              );

              return (
                <div key={index} className="faq-item">
                  <h3 className="faq-subheading">{finalQuestion}</h3>
                  {/* If you need HTML, use dangerouslySetInnerHTML. 
                      Otherwise, a simple <p> is fine. */}
                  <p className="faq-answer">{finalAnswer}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default FaqSection;