import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';
import HowRideNRepairWorks from './how-ride-n-repair-works';

// Images (if any banner or relevant images for “How We Work”)
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class HowWeWork extends Component {
  render() {
    const { isMobileBrowser, isMobileApp, user, setUser, citiesModal, setCitiesModal } = this.props;
    const canonicalUrl = 'https://www.ridenrepair.com/how-we-work';

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>How We Work - Ride N Repair</title>
          <meta
            name="description"
            content="Learn how Ride N Repair's doorstep bike service works. From booking online to real-time updates, see how we make two-wheeler maintenance hassle-free."
          />
          <meta
            name="keywords"
            content="How We Work, Ride N Repair, Bike Service App, Doorstep Bike Service, Online Bike Service, Mechanic at Doorstep"
          />
        </Helmet>

        {citiesModal && (
          <AvailableCitiesModal
            closeModal={() => setCitiesModal(false)}
          />
        )}

        <Header
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
          user={user}
          setUser={setUser}
        />

        <div className="page-content bg-white">
          {/* Banner Section */}
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: `url(${bnrImg})` }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>How We Work</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      How We Work
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <HowRideNRepairWorks />

          {/* How We Work Content */}
          {/* <section className="section-area section-sp2">
            <div className="container">
              <h2 className="title">HOW RIDE N REPAIR WORKS</h2>
              <p>
                At Ride N Repair, we make your two-wheeler servicing simple, quick, and hassle-free. Here’s how it works:
              </p>

              <h4>1. Choose your Vehicle & Service</h4>
              <p>
                Choose your vehicle brand and model, then explore a wide range of 
                maintenance and repair services for your bike or car. From servicing 
                and engine oil changes to jump starts, puncture fixes, and quick 
                repairs – we've got you covered.
              </p>

              <h4>2. Doorstep Mechanic or Free Pickup</h4>
              <p>
                Schedule our expert mechanics to visit your location or opt for a
                free pickup. No more waiting at service stations – we come right
                to your doorstep to ensure maximum convenience.
              </p>

              <h4>3. Real-Time Tracking & Instant Spare Parts</h4>
              <p>
                Stay informed every step of the way. Track your vehicle’s service
                progress in real time by downloading our app, and get instant spare 
                parts delivery and replacement at your doorstep – with transparent 
                updates on every repair.
              </p>

              <h4>4. Earn Rewards & Refer Friends</h4>
              <p>
                After a final quality check, your vehicle is returned to you in
                top shape. Share Ride N Repair with friends and family to earn
                referral rewards – Rs. 500 to you and your friends!
              </p>
            </div>
          </section> */}
        </div>

        <Footer
          setCitiesModal={setCitiesModal}
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
        />
      </>
    );
  }
}

export default HowWeWork;