import React, { Component } from 'react';
import '../../css/introduction.css'; // Specific CSS for styling

class Introduction extends Component {
  render() {
    const { cityKey='', areaKey='', cityContent } = this.props;

    let locationText = '';
    let formattedCityKey = '';

    if (cityKey) {
      // Capitalize the first letter of each word in cityKey
      formattedCityKey = cityKey
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

      // Find matching area object in cityContent (if cityKey is valid)
      const areaObj = cityContent[cityKey]?.areas?.find(
        (area) => area.value === areaKey
      );
      // If found, we have a label for display
      const areaLabel = areaObj?.label || '';
    
      if (cityKey && areaLabel) {
        locationText = ` in ${areaLabel}, ${formattedCityKey}`;
      } else if (cityKey) {
        locationText = ` in ${formattedCityKey}`;
      }
    }

    const cityData = {
      intro: `Looking for reliable & Best bike and car repair services${locationText}? Get your two-wheeler and four-wheeler serviced right at your doorstep without visiting a garage${locationText}. Get bike engine repair, doorstep battery replacement, wheel, and tyre care services at your home. Our expert mechanics provide quick, affordable, and high-quality bike and car repairs, ensuring your vehicle stays in top condition.`,
    };    

    const { intro } = cityData;

    return (
      <>
        <section className="introduction-section">
          <div className="container">
            <div className="heading-bx text-center">
              <h2>Two/Four wheeler repair at Home{cityKey && ` in ${formattedCityKey}`}</h2>
            </div>
            <div className="content">
              <span
                className="intro-text"
                dangerouslySetInnerHTML={{ __html: intro }}
              />
              <p className="city-text">
                <h2 style = {{ color: '#f42222' }}>Mechanic on Call <a style = {{ color: '#f42222' }} href="tel:+911205232850">+911205232850</a></h2>
              </p>
              <br/>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Introduction;
