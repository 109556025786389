// AppLinks.jsx
import React, { Component } from 'react';

// Images
import googlePlayIcon from '../../images/GooglePlayIcon.webp';
import appStoreIcon from '../../images/AppleAppStoreIcon.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../../css/app-links.css';

class AppLinks extends Component {
  render() {
    const { isMobileApp, cityKey='', areaKey='', cityContent } = this.props;

    let locationText = '';

    if (cityKey) {
      // Capitalize the first letter of each word in cityKey
      const formattedCityKey = cityKey
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

      // Find matching area object in cityContent (if cityKey is valid)
      const areaObj = cityContent[cityKey]?.areas?.find(
        (area) => area.value === areaKey
      );
      // If found, we have a label for display
      const areaLabel = areaObj?.label || '';

      
      if (cityKey && areaLabel) {
        locationText = ` in ${areaLabel}, ${formattedCityKey}`;
      } else if (cityKey) {
        locationText = ` in ${formattedCityKey}`;
      }
    }

    return (
      <section className="app-download-section">
        <div className="container">
          {/* App Download Section */}
          <div className="heading-bx text-center">
            <h2>Download Our App</h2>
            <p className="subtitle">
              Get hassle-free bike & car repair services right at your doorstep
              {locationText} with our mobile app!
            </p>
          </div>

          {!isMobileApp && !this.props.user.is_logged && (
            <div className="app-links-row">
              <div className="app-link-card">
                <a
                  href="https://play.google.com/store/apps/details?id=com.ridenrepair.app"
                  className="app-link"
                >
                  <div className="app-icon">
                    <LazyLoadImage src={googlePlayIcon} alt="Google Play Store" effect="blur" />
                  </div>
                  <span className="app-text">Google Play Store</span>
                </a>
              </div>
              <div className="app-link-card">
                <a
                  href="https://apps.apple.com/us/app/ridenrepair/id6446174085"
                  className="app-link"
                >
                  <div className="app-icon">
                    <LazyLoadImage src={appStoreIcon} alt="Apple App Store" effect="blur" />
                  </div>
                  <span className="app-text">Apple App Store</span>
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default AppLinks;