import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

// Import Images
import slide1Desktop from '../../../images/slider/slide1.webp';
import slide1Mobile from '../../../images/slider/mobile_optimized_slide1.webp';

const carDesktopImgUrl = 'https://ridenrepair.com/images/car.webp';
const carMobileImgUrl = 'https://ridenrepair.com/images/car_mobile.webp';
const wheelImgUrl = 'https://www.ridenrepair.com/images/wheel.webp';

const SliderOne = memo(({ isMobileBrowser, cityKey = '', areaKey = '', cityContent, setCitiesModal }) => {
  // Choose the appropriate image based on isMobileBrowser prop
  const backgroundImage = isMobileBrowser ? slide1Mobile : slide1Desktop;

  let locationText = '';

  if (cityKey) {
    // Capitalize the first letter of each word in cityKey
    const formattedCityKey = cityKey
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

    // Find matching area object in cityContent (if cityKey is valid)
    const areaObj = cityContent[cityKey]?.areas?.find(
      (area) => area.value === areaKey
    );
    // If found, we have a label for display
    const areaLabel = areaObj?.label || '';
    
    if (cityKey && areaLabel) {
      locationText = ` in ${areaLabel}, ${formattedCityKey}`;
    } else if (cityKey) {
      locationText = ` in ${formattedCityKey}`;
    }
  }

  return (
    <>
      <Helmet>
        {/* Preload background images */}
        <link
          rel="preload"
          as="image"
          href={slide1Desktop}
          media="(min-width: 769px)"
        />
        <link
          rel="preload"
          as="image"
          href={slide1Mobile}
          media="(max-width: 768px)"
        />
      </Helmet>

      <div className="ttr-slider style-2">
        <div className="slider-img slide-move">
          {/* Including an img tag for SEO and accessibility */}
          <img
            src={backgroundImage}
            alt="Bike & Car Repair Services at your Doorstep"
            className="visually-hidden"
            loading="eager"
          />
        </div>
        <div className="slider-content container text-center text-white">
          <div className="content-inner">
            <h1 style={{ paddingTop: '15%', zIndex: '100' }}>
              Bike & Car Repair Services at your Doorstep{locationText}
            </h1>
            <div style={{ zIndex: 9, paddingBottom: '20%' }}>
              <button onClick={() => setCitiesModal(true)}>
                CITIES WE SERVE
              </button>
            </div>
            <div className="car-wheel" style={{ opacity: '0.6' }}>
              <picture>
                <source
                  media="(max-width: 1024px)"
                  srcSet={carMobileImgUrl}
                  type="image/webp"
                />
                <img
                  src={carDesktopImgUrl}
                  alt="Car Image"
                  loading="eager"
                  fetchPriority="high"
                />
              </picture>
              <div className="wheel-1">
                <img
                  src={wheelImgUrl}
                  alt="Car Wheel Image"
                  loading="eager"
                  fetchPriority="high"
                />
              </div>
              <div className="wheel-2">
                <img
                  src={wheelImgUrl}
                  alt="Car Wheel Image"
                  loading="eager"
                  fetchPriority="high"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SliderOne;
