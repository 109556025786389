import React, { Component } from 'react';

// Layout
import Footer from "../layout/footer";

// Elements
import Header from "../layout/header";
import SliderOneSection from "../elements/home-slider/slider1";
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import AppLinks from "../elements/app-links";
import TestimonialSection from "../elements/testimonial";
import PartnerShipsSection from '../elements/partnerships';
import Modal from "../elements/modal";
import { Helmet } from 'react-helmet';
import AvailableCitiesModal from '../elements/available-cities-modal';
import Introduction from '../elements/city_introduction';
import FaqSection from '../elements/faq-section';
import Referral from '../elements/referral';
import HowRideNRepairWorks from '../pages/how-ride-n-repair-works';
import BlogGridPaginated from '../pages/blog-grid-paginated';

// import modalImg from "../../images/background/optimized_modal_img.webp";

// const modalImgBase64 = "data:image/webp;base64,YOUR_BASE64_STRING_HERE";

class Index extends Component {
  componentDidMount() {
    const { setOpenModal, isMobileApp, history } = this.props;

    // Check if phone is provided or ignored, open modal if not
    if (
      localStorage.getItem('phone_provided') === 'yes' ||
      sessionStorage.getItem('phone_ignored') === 'yes' ||
      this.props.user.is_logged
    ) {
      setOpenModal(false);
    }

    // Redirect to /register if it's a mobile app (WebView)
    if (isMobileApp) {
      history.push('/register');
    }
  }

  render() {
    const {
      isMobileApp,
      isMobileBrowser,
      cityKey,
      areaKey,
      cityContent,
      user,
      setUser,
      openModal,
      setOpenModal,
      citiesModal,
      setCitiesModal,
    } = this.props;

    // 1) Format cityKey, areaKey
    const formattedCityKey = cityKey
      ? cityKey
          .split('-')
          .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
          .join(' ')
      : '';

    // find area label if cityKey valid
    let areaLabel = '';
    if (cityKey && cityContent[cityKey]) {
      const areaObj = cityContent[cityKey]?.areas?.find(
        (a) => a.value === areaKey
      );
      if (areaObj) areaLabel = areaObj.label;
    }

    // 2) Build dynamic canonical
    //    If no cityKey => homepage
    //    If cityKey => "https://www.ridenrepair.com/city/<cityKey>"
    //    If cityKey + area => "https://www.ridenrepair.com/city/<cityKey>/<areaKey>"
    let pageCanonical = 'https://www.ridenrepair.com/';
    let pageTitle = 'Ride N Repair - Car and Bike Service and Repair at your Doorstep';
    let pageDescription =
      'Book your bike service online with Ride N Repair. Expert mechanics at your doorstep for hassle-free repairs.';

    if (cityKey) {
      if (areaKey && areaLabel) {
        // city + area
        pageCanonical = `https://www.ridenrepair.com/city/${cityKey}/${areaKey}`;
        pageTitle = `Doorstep Car & Bike Repair in ${areaLabel}, ${formattedCityKey} | Ride N Repair`;
        pageDescription = `Get professional two-wheeler and four-wheeler repairs in ${areaLabel}, ${formattedCityKey}. Enjoy doorstep service, quick fixes, and affordable pricing with Ride N Repair.`;
      } else {
        // city only
        pageCanonical = `https://www.ridenrepair.com/city/${cityKey}`;
        pageTitle = `Doorstep Car & Bike Repair in ${formattedCityKey} | Ride N Repair`;
        pageDescription = `Looking for hassle-free bike and car service in ${formattedCityKey}? Book online with Ride N Repair for doorstep repairs, expert mechanics, and quick turnarounds.`;
      }
    }

    return (
      <>
        <Helmet>
          <link rel="canonical" href={pageCanonical} />
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta
            name="keywords"
            content="Two-Wheeler Service, Two-Wheeler Repair, Bike Maintenance, Scooter Service, Activa Service, Pulsar Service, Hero Honda Service, TVS Service, Motorcycle Repair, Online Booking, Ride N Repair, Doorstep Bike Service, Book Bike Service, Schedule Service, Expert Mechanics, Ahmedabad, Bengaluru, Bhopal, Bhubaneswar, Chandigarh, Dehradun, Delhi, Faridabad, Ghaziabad, Greater Noida, Gurugram, Guwahati, Hyderabad, Indore, Jaipur, Kalyan, Kolkata, Mumbai, Mysore, Nagpur, Nashik, Navi Mumbai, Noida, Pune, Surat, Thane, Vishakhapatnam"
          />

          {/* Preload modal image */}
          {/* <link
            rel="preload"
            as="image"
            href="https://ridenrepair.com/images/optimized_modal_img.webp"
            fetchpriority="high"
            type="image/webp"
          /> */}

					{/* Preload modal image */}
					{/* <link rel="preload" as="image" href={modalImg} /> */}

          {/* Microsoft Clarity script */}
          <script type="text/javascript">
            {`
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.defer=true;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "ogtpv3dzyx");
            `}
          </script>
        </Helmet>

				{/* Phone Number Modal */}
				{!isMobileApp && openModal && (
					<Modal
						isMobileBrowser={isMobileBrowser}
						isMobileApp={isMobileApp}
						openModal={openModal}
						setOpenModal={setOpenModal}
						user={user}
						setUser={setUser}
					/>
				)}

        {/* Available Cities Modal */}
        {citiesModal && (
          <AvailableCitiesModal closeModal={() => setCitiesModal(false)} />
        )}

        <Header isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} user={user} setUser={setUser} />

        <SliderOneSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} setCitiesModal={setCitiesModal} areaKey={areaKey} cityContent={cityContent} />

        {/* App Links Section - Hide if in Mobile App */}
        {!isMobileApp && !this.props.user.is_logged && <AppLinks user={user} setUser={setUser} isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} areaKey={areaKey} cityContent={cityContent} />}

        <Introduction isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} areaKey={areaKey} cityContent={cityContent} />

        <Referral />

        {/* FAQ Section - Show if cityKey is provided */}
        <FaqSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} areaKey={areaKey} cityContent={cityContent} />

        {/* <AboutUsTwoSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} areaKey={areaKey} cityContent={cityContent} /> */}

        <HowRideNRepairWorks isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} />

        {/* Uncomment and update ServicesSection as needed */}
        {/* <ServicesSection vehicleType={''} model={''} servicesData={[]} user={user} setUser={setUser} /> */}

        <ChooseUsSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} areaKey={areaKey} cityContent={cityContent} />

        <TestimonialSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} />

        {/* <BlogGridSidebar isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} /> */}
        <BlogGridPaginated blogsData={this.props.blogsData} postsPerPage={3} />

        <PartnerShipsSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} areaKey={areaKey} cityContent={cityContent} />

        <Footer isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} setCitiesModal={setCitiesModal} />
      </>
    );
  }
}

export default Index;