// src/markup/elements/Chatbot.jsx

import React, { Component } from 'react';
import axios from 'axios';
import '../../css/chatbot.css';
import zIndex from '@mui/material/styles/zIndex';

class Chatbot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChatbot: false,
      showVerificationModal: false,
      loadMapScripts: false,
      isScriptLoaded: false,
      phone: '',
      email: '',
      phoneError: '',
      emailError: '',
      phoneOtp: '',
      emailOtp: '',
      isPhoneVerified: false,
      isEmailVerified: false,
      isSendingPhoneOtp: false,
      isSendingEmailOtp: false,
      showPhoneOtpInput: false,
      showEmailOtpInput: false,
      requestingLocation: false,
      messages: [
        {
          sender: 'bot',
          text: 'Welcome to Ride N Repair! Could you please specify whether your vehicle is a car or a bike?',
        },
      ],
      userMessage: '',
      isLoading: false,
      showOptions: false,
      showChatIcon: true,
      googleAddress: '',
      city: '',
      stateValue: '',
      zip: '',
      latitude: '',
      longitude: '',
    };

    this.requestLocationPermission = this.requestLocationPermission.bind(this);
    this.loadGoogleMapsScripts = this.loadGoogleMapsScripts.bind(this);
    this.getChatbotCurrentLocation = this.getChatbotCurrentLocation.bind(this);
    this.handleLocationSuccess = this.handleLocationSuccess.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
    this.getComponentType = this.getComponentType.bind(this);
    this.sendLocationErrorMessage = this.sendLocationErrorMessage.bind(this);
  }

  // Handle toggling the chatbot visibility
  toggleChatbot = () => {
    const { isPhoneVerified, isEmailVerified } = this.state;
    if (isPhoneVerified && isEmailVerified) {
      this.setState({ showChatbot: true, showChatIcon: false });
    } else {
      this.setState({ showVerificationModal: true, showChatIcon: false });
    }
  };

  // Utility function to extract component from address_components
  getComponentType = (type, components) => {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : '';
  };

  // Validate phone number format
  validatePhone = () => {
    const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const isValidPhone = phoneRegex.test(this.state.phone);
    if (isValidPhone) {
      this.setState({ phoneError: '' });
    } else {
      this.setState({ phoneError: 'Invalid Phone Number' });
    }
  };

  // Validate email format
  validateEmail = () => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(this.state.email);
    if (isValidEmail) {
      this.setState({ emailError: '' });
    } else {
      this.setState({ emailError: 'Invalid Email' });
    }
  };

  // Start phone verification by sending OTP
  startPhoneVerification = async () => {
    await this.validatePhone();
    if (this.state.phoneError !== '') return;

    const params = { phone: this.state.phone };
    try {
      this.setState({ isSendingPhoneOtp: true });
      await axios.post(
        'https://www.ridenrepair.com/api/send_booking_otp_sms',
        params
      );
      this.setState({ showPhoneOtpInput: true, isSendingPhoneOtp: false });
    } catch (error) {
      this.setState({ isSendingPhoneOtp: false });
    }
  };

  // Start email verification by sending OTP
  startEmailVerification = async () => {
    await this.validateEmail();
    if (this.state.emailError !== '') return;

    const params = { email: this.state.email };
    try {
      this.setState({ isSendingEmailOtp: true });
      await axios.post(
        'https://www.ridenrepair.com/api/send_booking_otp_email',
        params
      );
      this.setState({ showEmailOtpInput: true, isSendingEmailOtp: false });
    } catch (error) {
      this.setState({ isSendingEmailOtp: false });
    }
  };

  // Handle phone OTP verification
  handlePhoneOtpVerification = async () => {
    const params = {
      phone: this.state.phone,
      booking_otp: this.state.phoneOtp,
    };
    try {
      const res = await axios.post(
        'https://www.ridenrepair.com/api/validate_booking_otp',
        params
      );
      if (res.data.is_valid) {
        this.setState({ isPhoneVerified: true, phoneError: '' });
        try {
          const leadParams = {
            phone: this.state.phone,
            lead_source: 'ChatBot',
          };
          axios.post("https://ridenrepair.com/api/add_phone_lead", leadParams);
        } catch (error) { }

        localStorage.setItem('userLogin', params.phone);
        if (this.state.isEmailVerified) {
          const userParams = {
            email: this.state.email,
            phone: this.state.phone
          }
          try {
            axios.post("https://ridenrepair.com/api/add_chatbot_user", userParams);
          } catch (error) {
            
          }
          localStorage.setItem('userPhone', userParams.phone);
          localStorage.setItem('userEmail', userParams.email);
          
          this.props.setUser({is_logged: true, email: this.state.email, ph: this.state.phone});
        }
      } else {
        this.setState({ phoneError: 'Incorrect Phone OTP' });
      }
    } catch (error) {
    }
  };

  // Handle email OTP verification
  handleEmailOtpVerification = async () => {
    const params = {
      email: this.state.email,
      email_otp: this.state.emailOtp,
    };
    try {
      const res = await axios.post(
        'https://www.ridenrepair.com/api/validate_email_otp',
        params
      );
      if (res.data.is_valid) {
        this.setState({ isEmailVerified: true, emailError: '' });
        if (this.state.isPhoneVerified) {
          const userParams = {
            email: this.state.email,
            phone: this.state.phone
          }
          try {
            axios.post("https://ridenrepair.com/api/add_chatbot_user", userParams);
          } catch (error) {
            
          }
          localStorage.setItem('userPhone', userParams.phone);
          localStorage.setItem('userEmail', userParams.email);
          
          this.props.setUser({is_logged: true, email: this.state.email, ph: this.state.phone});
        }
      } else {
        this.setState({ emailError: 'Incorrect Email OTP' });
      }
    } catch (error) {
    }
  };

  componentDidMount() {
    const phone = localStorage.getItem('userPhone');
    const email = localStorage.getItem('userEmail');
    if (phone && email) {
      this.setState({ phone, email, isPhoneVerified: true, isEmailVerified: true })
      this.props.setUser({is_logged: true, email: email, ph: phone});
    }
    if (this.props.user.is_logged) {
      this.setState({ phone: this.props.user.ph, email: this.props.user.email, isPhoneVerified: true, isEmailVerified: true })
    }
  }

  // Define the scrollToBottom method
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loadMapScripts && !prevState.loadMapScripts) {
      this.loadGoogleMapsScripts();
    }
    // Auto-scroll when messages update
    if (prevState.messages.length !== this.state.messages.length) {
      this.scrollToBottom();
    }
  }

  loadGoogleMapsScripts() {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAvEcAoXc_eUTHDbNWKqOZpmS4FsRBCOG8&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.setState({ isScriptLoaded: true }, this.getChatbotCurrentLocation);
      };
      script.onerror = () => {
        this.sendLocationErrorMessage('Failed to load Google Maps scripts.');
      };
      document.head.appendChild(script);
    } else {
      this.setState({ isScriptLoaded: true }, this.getChatbotCurrentLocation);
    }
  }

  getChatbotCurrentLocation() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleLocationSuccess,
          this.handleLocationError,
          { enableHighAccuracy: false, timeout: 15000, maximumAge: 0 }
        );
      } else {
        this.sendLocationErrorMessage('Geolocation is not supported by your browser.');
      }
    } catch (error) {
      this.sendLocationErrorMessage('Unexpected error occurred while fetching location.');
    }
  }

  // Request location permission and handle location
  requestLocationPermission() {
    this.setState({ loadMapScripts: true });
  }

  handleLocationSuccess(position) {
    const { latitude, longitude } = position.coords;
    this.setState({ latitude, longitude });
  
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: latitude, lng: longitude };
  
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          const address = results[0].formatted_address;
          const city =
            this.getComponentType('locality', addressComponents) ||
            this.getComponentType('administrative_area_level_2', addressComponents);
          const stateValue = this.getComponentType('administrative_area_level_1', addressComponents);
          const zip = this.getComponentType('postal_code', addressComponents);
  
          this.setState({
            locationAddress: address,
            city,
            stateValue,
            zip,
          });
  
          // Compose the message
          const locationMessage = `My google_address is ${address}. My latitude is ${latitude}. My longitude is ${longitude}. My city is ${city}. My state is ${stateValue}. My zipcode is ${zip}.`;
  
          const { phone, email, messages } = this.state;
          const newMessages = [
            ...messages,
            { sender: 'user', text: 'Location data fetched correctly.' },
          ];
  
          this.setState({
            messages: newMessages,
            requestingLocation: false,
            isLoading: true,
          });
  
          // Send the location message to the backend
          axios
            .post('https://www.ridenrepair.com/api/chat/', {
              phone: phone,
              email: email,
              message: locationMessage,
            })
            .then((response) => {
              const botMessage = response.data.response;
              this.setState({
                messages: [
                  ...newMessages,
                  { sender: 'bot', text: botMessage },
                ],
                isLoading: false,
              });
            })
            .catch((error) => {
              this.setState({
                messages: [
                  ...newMessages,
                  {
                    sender: 'bot',
                    text: 'Sorry, something went wrong.',
                  },
                ],
                isLoading: false,
              });
            });
        } else {
          this.sendLocationErrorMessage('No results found for your location.');
        }
      } else {
        this.sendLocationErrorMessage('Geocoder failed to retrieve your address.');
      }
    });
  }

  // Handle location error
  handleLocationError = (error) => {
    this.sendLocationErrorMessage(
      'Unable to retrieve your location. Please enter your address manually.'
    );
  };

  // Send location error message
  sendLocationErrorMessage = (message) => {
    const { messages } = this.state;
    this.setState({
      messages: [...messages, { sender: 'bot', text: message }],
    });
  };

  // Handle user message input
  handleUserMessageChange = (e) => {
    this.setState({ userMessage: e.target.value });
  };

  // Send user message to backend and get response
  sendMessage = async () => {
    const { phone, email, userMessage, messages } = this.state;
    if (userMessage.trim() === '') return;

    const newMessages = [...messages, { sender: 'user', text: userMessage }];
    this.setState({ messages: newMessages, userMessage: '', isLoading: true });

    try {
      const response = await axios.post(
        'https://www.ridenrepair.com/api/chat/',
        {
          phone: phone,
          email: email,
          message: userMessage,
        }
      );
      const botMessage = response.data.response;

      // Check if the bot is requesting location
      if (botMessage.endsWith('#request_location#')) {
        // Extract the actual message
        const actualMessage = botMessage.replace('#request_location#', '').trim();
        this.setState({
          messages: [...newMessages, { sender: 'bot', text: actualMessage }],
          isLoading: false,
        });

        // Trigger location request
        this.requestLocationPermission();
      } else {
        this.setState({
          messages: [...newMessages, { sender: 'bot', text: botMessage }],
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        messages: [
          ...newMessages,
          { sender: 'bot', text: 'Sorry, something went wrong.' },
        ],
        isLoading: false,
      });
    }
  };

  // Render the verification modal
  renderVerificationModal = () => {
    const {
      phone,
      email,
      phoneError,
      emailError,
      showPhoneOtpInput,
      showEmailOtpInput,
      isSendingPhoneOtp,
      isSendingEmailOtp,
      isPhoneVerified,
      isEmailVerified,
      phoneOtp,
      emailOtp,
    } = this.state;

    const heading = this.getVerificationHeading();

    return (
      <div className="verification-modal">
        <div className="modal-content">
          <div
            className="chat-header"
            style={{ backgroundColor: 'white' }}
          >
            <img
              src={require('../../images/logo.png')}
              alt="Ride N Repair"
              className="logo-small"
            />
            <button
              className="close-button"
              onClick={() =>
                this.setState({
                  showOptions: false,
                  showChatbot: false,
                  showVerificationModal: false,
                  showChatIcon: true,
                })
              }
            >
              ✕
            </button>
          </div>
          {heading && <h4>{heading}</h4>}
          {isPhoneVerified && isEmailVerified && (
            <h6>You are verified! Click below to start chatting.</h6>
          )}
          {!isPhoneVerified && (
            <div className="input-group">
              <input
                type="text"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
                onBlur={this.validatePhone}
              />
              <button
                onClick={this.startPhoneVerification}
                disabled={isSendingPhoneOtp}
              >
                {isSendingPhoneOtp
                  ? 'Phone OTP being sent...'
                  : 'Send Phone OTP'}
              </button>
              {phoneError && <p className="error">{phoneError}</p>}
            </div>
          )}
          {showPhoneOtpInput && !isPhoneVerified && (
            <div className="input-group">
              <input
                type="text"
                placeholder="Enter Phone OTP"
                value={phoneOtp}
                onChange={(e) => this.setState({ phoneOtp: e.target.value })}
              />
              <button onClick={this.handlePhoneOtpVerification}>
                Verify Phone OTP
              </button>
            </div>
          )}
          {!isEmailVerified && (
            <div className="input-group">
              <input
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
                onBlur={this.validateEmail}
              />
              <button
                onClick={this.startEmailVerification}
                disabled={isSendingEmailOtp}
              >
                {isSendingEmailOtp
                  ? 'Email OTP being sent...'
                  : 'Send Email OTP'}
              </button>
              {emailError && <p className="error">{emailError}</p>}
            </div>
          )}
          {showEmailOtpInput && !isEmailVerified && (
            <div className="input-group">
              <input
                type="text"
                placeholder="Enter Email OTP"
                value={emailOtp}
                onChange={(e) => this.setState({ emailOtp: e.target.value })}
              />
              <button onClick={this.handleEmailOtpVerification}>
                Verify Email OTP
              </button>
            </div>
          )}
          {isPhoneVerified && isEmailVerified && (
            <button
              className="start-chat-button"
              onClick={() =>
                this.setState({
                  showVerificationModal: false,
                  showChatbot: true,
                })
              }
            >
              Start Chatting
            </button>
          )}
        </div>
      </div>
    );
  };

  // Render the chatbot interface
  renderChatbotInterface = () => {
    const { messages, userMessage, isLoading } = this.state;

    return (
      <div className="chatbot-interface">
        <div
          className="chat-header"
          style={{ backgroundColor: 'white' }}
        >
          <img
            src={require('../../images/logo.png')}
            alt="Ride N Repair"
            className="logo-small"
          />
          <h6>Chat with our AI</h6>
          <button
            className="close-button"
            onClick={() =>
              this.setState({
                showOptions: false,
                showChatbot: false,
                showChatIcon: true,
              })
            }
          >
            ✕
          </button>
        </div>
        <div className="chat-body">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${
                msg.sender === 'user' ? 'user-message' : 'bot-message'
              }`}
            >
              {msg.text}
            </div>
          ))}
          {isLoading && <div className="loading-message">Typing...</div>}
        </div>
        <div ref={(el) => (this.messagesEnd = el)}></div>
        <div className="chat-footer">
          <input
            type="text"
            placeholder="Type your message..."
            value={userMessage}
            onChange={this.handleUserMessageChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') this.sendMessage();
            }}
          />
          <button
            onClick={this.sendMessage}
            disabled={userMessage.trim() === ''}
          >
            Send
          </button>
        </div>
      </div>
    );
  };

  // Get verification heading
  getVerificationHeading = () => {
    const { isPhoneVerified, isEmailVerified } = this.state;
    if (isPhoneVerified && isEmailVerified) {
      return null; // No heading
    } else {
      return 'Verify Your Details';
    }
  };

  // Render the initial chat options (Chatbot and WhatsApp icons)
  renderChatOptions = () => {
    return (
      <div className="chat-options">
        <button
          className="chat-option-button"
          onClick={this.toggleChatbot}
        >
          <img
            src={require('../../images/chatbot_icon.png')}
            alt="Chatbot"
          />
          Book with our Smart AI
        </button>
        <a
          href="https://wa.me/917302224253?text=Hi%2C%0A%0AI%20would%20like%20to%20book%20a%20service."
          target="_blank"
          rel="noopener noreferrer"
          className="chat-option-button"
        >
          <img
            src={require('../../images/whatsapp_icon.png')}
            alt="WhatsApp"
          />
          Book via WhatsApp
        </a>
      </div>
    );
  };

  // Main render method
  render() {
    const {
      showOptions,
      showChatbot,
      showVerificationModal,
      showChatIcon,
    } = this.state;

    const { isMobileApp } = this.props;

    return (
      <div style={{ position: 'absolute', zIndex: 99999 }}>
        {showChatIcon && (
          <div
            className="chat-icon"
            onClick={() =>
              {
                if (isMobileApp || this.props.user.is_logged) {
                  this.toggleChatbot();
                } else {
                  this.setState((prevState) => ({
                    showOptions: !prevState.showOptions,
                  }))
                }
              }
            }
          >
            <img
              src={require('../../images/chatbot_icon.png')}
              alt="Chat"
            />
          </div>
        )}
        {showOptions && this.renderChatOptions()}
        {showVerificationModal && this.renderVerificationModal()}
        {showChatbot && this.renderChatbotInterface()}
      </div>
    );
  }
}

export default Chatbot;
