import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../css/available-cities-modal.css';
import { cities } from './cities-data';
import indiaFlag from "../../images/cities/india_flag.png";

class AvailableCitiesModal extends Component {
  constructor(props) {
    super(props);
    this.createSlug = this.createSlug.bind(this);
  }
  
  /**
   * Converts a city name into a URL-friendly slug.
   * Example: "New Delhi" -> "new-delhi"
   * @param {string} title - The city name.
   * @returns {string} - The slugified city name.
   */
  createSlug(title) {
    return title
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')       // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '')    // Remove all non-word characters except hyphens
      .replace(/--+/g, '-')       // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, '');   // Remove leading and trailing hyphens
  }

  render() {
    const { closeModal } = this.props;
    const sortedCities = cities.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="cities-modal">
        <div className="india-header">
          <img src={indiaFlag} alt="India Flag" className="india-flag" loading="lazy"/>&nbsp;&nbsp;
          <h4 className='india-header'>INDIA</h4>
        </div>
        <a class="modal-popup__close" href="#" onClick={this.props.closeModal}>X</a>
        <div className="city-list">
          {sortedCities.map((city) => (
            <div key={city.key} className="city-item">
              {/* <img src={city.image} alt={city.name} /> */}
              {/* <span>{city.name}</span> */}
              <Link
                  to={`/city/${this.createSlug(city.name)}`}
                  onClick={closeModal}
                  className="city-link"
                  aria-label={`Navigate to ${city.name} page`}
                >
                  {city.name}
                </Link>
            </div>
          ))}
        </div>
        <div className="close-button-row">
          <button onClick={this.props.closeModal}>Close</button>
        </div>
      </div>
    );
  }
}

export default AvailableCitiesModal;