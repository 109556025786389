import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Images
import pic6 from "../../images/about/optimized_chooseus.webp";

class ChooseUsSection extends Component {
  render() {
    const { cityKey='', areaKey='', cityContent } = this.props;

    // If cityKey is blank, skip area check and keep locationText blank.
    let locationText = '';

    if (cityKey) {
      // Capitalize the first letter of each word in cityKey
      const formattedCityKey = cityKey
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

      // Find matching area object in cityContent (if cityKey is valid)
      const areaObj = cityContent[cityKey]?.areas?.find(
        (area) => area.value === areaKey
      );
      // If found, we have a label for display
      const areaLabel = areaObj?.label || '';

      if (cityKey && areaLabel) {
        locationText = ` in ${areaLabel}, ${formattedCityKey}`;
      } else if (cityKey) {
        locationText = ` in ${formattedCityKey}`;
      }
    }
    
    return (
      <>
        <section className="section-area bg-white section-sp1">
          <div className="container">
            <div className="row align-items-center">
              {/* Image Column */}
              <div className="col-lg-6 mb-30">
                <div className="choose-media">
                  <LazyLoadImage
                    src={pic6}
                    alt="Why choose Ride N Repair"
                    effect="blur"
                  />
                </div>
              </div>

              {/* Text Column */}
              <div className="col-lg-6 mb-30">
                <div className="heading-bx mb-30">
                  <h2 className="title mb-0">WHY CHOOSE US</h2>
                  {/* <p>
                    We offer a seamless <strong>online bike service</strong> and <strong>car service</strong> at your doorstep with just a few clicks. Whether you need a <strong>bike service app</strong> or <strong>car service app</strong> for easy booking, or you're looking for <strong>doorstep repair near me</strong> for quick fixes, we've got you covered. From <strong>puncture repair</strong> and <strong>engine oil changes</strong> to <strong>jump start</strong> assistance, we ensure fast, reliable service for both two-wheelers and four-wheelers wherever you are.
                  </p>

                  <p>
                    From regular maintenance to specialized tasks like <strong>bike dent repair near me</strong> or <strong>car dent repair near me</strong>, our expert mechanics handle everything with professional care. We pride ourselves on offering transparent pricing and quality workmanship.
                  </p>

                  <ul>
                    <li>
                      <strong>Doorstep Bike &amp; Car Service:&nbsp;</strong> 
                      We bring our expertise right to your home or office, saving you time and hassle.
                    </li>
                    <li>
                      <strong>Online Booking Made Easy:&nbsp;</strong> 
                      Schedule a slot via our <strong>bike service app</strong> or <strong>car service app</strong> in seconds.
                    </li>
                    <li>
                      <strong>Qualified Mechanics for Every Brand:&nbsp;</strong> 
                      From an <strong>Activa mechanic near me</strong> to a Royal Enfield specialist, or a certified car technician for Hyundai and Maruti, our professionals are highly skilled.
                    </li>
                    <li>
                      <strong>Transparency &amp; Trust:&nbsp;</strong> 
                      Enjoy real-time service updates and detailed breakdowns of any <strong>repair or part replacement</strong>.
                    </li>
                    <li>
                      <strong>Emergency Assistance &amp; Jump Starts:&nbsp;</strong> 
                      Stuck on the road? Our on-demand mechanics can help with urgent <strong>puncture repair</strong>, <strong>jump starts</strong>, and more.
                    </li>
                  </ul>

                  <p>
                    Whether you need <strong>two-wheeler service online</strong> or a complete <strong>car service at doorstep</strong>, Ride N Repair delivers dependable solutions. Book now to experience a hassle-free <strong>mechanic at your doorstep</strong> for all your bike and car repair needs!
                  </p> */}

<p>
                    We offer a seamless <strong>online bike service</strong> and <strong>car service</strong> at your doorstep with just a few clicks. Whether you need a <strong>bike service app</strong> or <strong>car service app</strong> for easy booking, or you're looking for <strong>doorstep repair near me</strong> for quick fixes, we've got you covered. From <strong>puncture repair</strong> and <strong>engine oil changes</strong> to <strong>jump start</strong> assistance, we ensure fast, reliable service for both two-wheelers and four-wheelers wherever you are
                    {locationText}.
                  </p>

                  <p>
                    From regular maintenance to specialized tasks like <strong>bike dent repair near me</strong> or <strong>car dent repair near me</strong>, our expert mechanics handle everything with professional care
                    {locationText}. We pride ourselves on offering transparent pricing and quality workmanship.
                  </p>

                  <ul>
                    <li>
                      <strong>Doorstep Bike &amp; Car Service:&nbsp;</strong>
                      We bring our expertise right to your home or office, saving you time and hassle.
                    </li>
                    <li>
                      <strong>Online Booking Made Easy:&nbsp;</strong>
                      Schedule a slot via our <strong>bike service app</strong> or <strong>car service app</strong> in seconds.
                    </li>
                    <li>
                      <strong>Qualified Mechanics for Every Brand:&nbsp;</strong>
                      From an <strong>Activa mechanic near me</strong> to a Royal Enfield specialist, or a certified car technician for Hyundai and Maruti, our professionals are highly skilled.
                    </li>
                    <li>
                      <strong>Transparency &amp; Trust:&nbsp;</strong>
                      Enjoy real-time service updates and detailed breakdowns of any <strong>repair or part replacement</strong>.
                    </li>
                    <li>
                      <strong>Emergency Assistance &amp; Jump Starts:&nbsp;</strong>
                      Stuck on the road? Our on-demand mechanics can help with urgent <strong>puncture repair</strong>, <strong>jump starts</strong>, and more.
                    </li>
                  </ul>

                  <p>
                    Whether you need <strong>two-wheeler service online</strong> or a complete <strong>car service at doorstep</strong>, Ride N Repair delivers dependable solutions. Book now to experience a hassle-free <strong>mechanic at your doorstep</strong> for all your bike and car repair needs
                    {locationText}!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ChooseUsSection;
