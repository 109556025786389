import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BlogGridPaginated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  // Handler for clicking a page number or next/prev
  handleClick = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { blogsData, postsPerPage } = this.props;
    const { currentPage } = this.state;

    // Calculate indexes for current page
    const indexOfLast = currentPage * postsPerPage;
    const indexOfFirst = indexOfLast - postsPerPage;
    const currentBlogs = blogsData.slice(indexOfFirst, indexOfLast);

    // Total number of pages
    const totalPages = Math.ceil(blogsData.length / postsPerPage);

    return (
      <div>
        {/* Blog Grid */}
        <section className="section-area bg-white section-sp1">
          <div className="container">
            <div className="heading-bx mb-30">
              <h2 className="title mb-0">LATEST BLOGS</h2>
              <div className="row">
                {currentBlogs.map((item, index) => (
                  <div className="col-xl-4 col-md-6" key={index}>
                    <div className="blog-card style-1 bg-white shadow">
                      <div className="post-media">
                        <Link to={{ 
                          pathname: `/blog-details/${item.slug || index}`, 
                          state: { blogItem: item } 
                        }}>
                          {item.thumb && <img src={item.thumb} alt={item.title} />}
                        </Link>
                      </div>
                      <div className="post-info">
                        <h4 className="post-title">
                          <Link to={{ 
                            pathname: `/blog-details/${item.slug || index}`, 
                            state: { blogItem: item } 
                          }}>
                            {item.title}
                          </Link>
                        </h4>
                        <div className="post-content">
                          <p className="mb-0">{item.summary || item.blogText}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="pagination-bx text-center clearfix">
                      <ul className="pagination">

                        {/* Prev Button */}
                        <li className={`previous ${currentPage <= 1 ? 'disabled' : ''}`}>
                          <button 
                            onClick={() => this.handleClick(currentPage - 1)} 
                            disabled={currentPage <= 1}
                          >
                            Prev
                          </button>
                        </li>

                        {/* Page Number Buttons */}
                        {Array.from({ length: totalPages }).map((_, i) => {
                          const pageNum = i + 1;
                          return (
                            <li 
                              key={i} 
                              className={currentPage === pageNum ? 'active' : ''}
                            >
                              <button onClick={() => this.handleClick(pageNum)}>
                                {pageNum}
                              </button>
                            </li>
                          );
                        })}

                        {/* Next Button */}
                        <li className={`next ${currentPage >= totalPages ? 'disabled' : ''}`}>
                          <button 
                            onClick={() => this.handleClick(currentPage + 1)} 
                            disabled={currentPage >= totalPages}
                          >
                            Next
                          </button>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default BlogGridPaginated;
