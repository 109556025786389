// Referral.jsx
import React from 'react';

const Referral = () => {
  return (
    <>
      <div className="heading-bx text-center">
        <br/>
        <h2>Refer & Earn ₹500</h2>
        <p className="subtitle">
          Enjoying our on-demand vehicle service? Spread the word and get rewarded! Here’s how:
        </p>
      </div>
      <div className="app-links-row">
        <div
          className="app-link-card"
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
        >
          <p>1. Share your unique referral code from your profile with friends.</p>
          <p>2. They receive ₹500 off their first service.</p>
          <p>3. Once they complete their service, ₹500 is automatically credited to you.</p>
        </div>
      </div>

      <div className="heading-bx text-center">
        <p className="subtitle">
          Even if you don’t need repairs now, keep our app for future use. Your referral code is valid whenever friends book!
        </p>
      </div>
    </>
  );
};

export default Referral;