import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import Modal from '../elements/modal';

// Images
import bikePrices from "../../data/bike_prices.json";

import AvailableCitiesModal from '../elements/available-cities-modal';

import vehicleData from '../../data/vehicle_data.json';
import axios from "axios";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileApp: false,
      vehicleType: 'bike',
      brand: '',
      model: '',
      fuelType: '',
      transmission: 'manual',
      ccRange: '',
			brandDropdownOpen: false,
			modelDropdownOpen: false,
			fuelTypeDropdownOpen: false,
			transmissionDropdownOpen: false,
			ccRangeDropdownOpen: false,
			isMouseInsideDropdown: false,
      carModels: [],
      bikeModels: [],
      userVehicles: [],
      selectedVehicleIndex: 0,
    };
  }

  handleBrandChange = (brand) => {
    // Filter and sort car models based on the selected car brand
    if (this.state.vehicleType === 'car') {
      const carModels = brand.models.slice().sort((a, b) => {
        // Compare model labels in a case-insensitive manner
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });
  
      this.setState({
        carModels: carModels,
        model: '',
      });
    } else {
      // Filter and sort bike models based on the selected bike brand
      const bikeModels = brand.models.slice().sort((a, b) => {
        // Compare model labels in a case-insensitive manner
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });
  
      this.setState({
        bikeModels: bikeModels,
        model: '',
      });
    }
  };  

	handleClickDropdown = (dropdownName) => {
    // Close all dropdowns except the one clicked
    Object.keys(this.state).forEach((key) => {
      if (key.endsWith('DropdownOpen') && key !== `${dropdownName}DropdownOpen`) {
        this.setState({ [key]: false });
      }
    });

    // Toggle the state of the clicked dropdown
    this.setState((prevState) => ({
      [`${dropdownName}DropdownOpen`]: !prevState[`${dropdownName}DropdownOpen`],
    }));
  };

	handleDropdownOptionsMouseEnter = () => {
    this.isMouseInsideDropdown = true;
  };
	
	handleDropdownOptionsMouseLeave = () => {
		this.isMouseInsideDropdown = false;
	};

	handleMouseEnter = (dropdownName) => {
    if (dropdownName === 'brand' && this.state.brand) {
      return;
    }
    if (dropdownName === 'model' && this.state.model) {
      return;
    }
    // Close all dropdowns except the one being hovered
    Object.keys(this.state).forEach((key) => {
      if (key.endsWith('DropdownOpen') && key !== `${dropdownName}DropdownOpen`) {
        this.setState({ [key]: false });
      }
    });

    // Set the state to indicate that the mouse is over the dropdown
    this.setState({ [`${dropdownName}DropdownOpen`]: true });
  };

	handleMouseLeave = (dropdownName) => {
		setTimeout(() => {
			if (!this.isMouseInsideDropdown) {
				this.setState({ [`${dropdownName}DropdownOpen`]: false });
			}
		}, 200);
	};

  handleFuelTypeChange = (type) => {
    this.setState({
      fuelType: type,
    });
  };

  handleCCRangeChange = (type) => {
    this.setState({
      ccRange: type,
    });
  };

  handleTransmissionChange = (type) => {
    this.setState({
      transmission: type,
    });
  };

  shouldDisplayServices = () => {
    const { brand, model, fuelType } = this.state;
    return brand && model && fuelType;
  }

  shouldDisplayServicesBike = () => {
    const { brand, model, ccRange } = this.state;
    return brand && model && ccRange;
  }

  getSelectedFuelTypeData = () => {
    const { brand, model, fuelType } = this.state;
    const selectedBrand = vehicleData.carBrands.find(item => item.label === brand.label);
    if (selectedBrand && selectedBrand.models) {
      const selectedModel = selectedBrand.models.find(item => item.label === model.label);
      if (selectedModel && selectedModel.fuelTypes) {
        const selectedFuelType = selectedModel.fuelTypes.find(item => item.value === fuelType);
        // currently only one category and sub-category is available
        return selectedFuelType['categories'][0]['sub-categories'][0]['name'];
      }
    }
    return null;
  }

  checkRider = (phone) => {
    return axios.get("https://ridenrepair.com/auto/check_rider", {
      params: {
        phone_number: phone
      }
    }).then(response => {
      const data = response.data;
      return data.exists;
    }).catch(error => {
      return false;
    });
  }  

  getSelectedCCData = () => {
    const { ccRange } = this.state;
    return bikePrices[ccRange];
  }

  handleVehicleTypeChange = (type) => {
    this.setState({
      vehicleType: type,
      brand: '',
      model: '',
      fuelType: '',
      transmission: 'manual',
      ccRange: '',
    });

		this.closeDropdowns();
    this.resetModels();
  };

  renderDropdown = (options, selectedOption, type, prefix) => {
    return (
      <div className="vehicle-dropdown">
        <div
          className="dropdown-label"
          onMouseEnter={() => this.handleMouseEnter(prefix)}
          onMouseLeave={() => this.handleMouseLeave(prefix)}
          onClick={() => this.handleClickDropdown(prefix)}
          ref={(ref) => (this.dropdownRef = ref)}
        >
          {selectedOption && (
            <div className="selected-option">
              { prefix === 'brand' && <img src={require(`../../images/brand_logos/${selectedOption.logo}`)} alt={`${selectedOption.label} brand logo`} className="brand-image selected-image" /> }
              <span>{selectedOption.label}</span>
            </div>
          )}
          {!selectedOption && `Select ${type}`}
          <div className="arrow-down"></div>
        </div>
        {this.state[`${prefix}DropdownOpen`] && (
          <div className="dropdown-options" onMouseEnter={() => this.handleDropdownOptionsMouseEnter()} onMouseLeave={() => this.handleDropdownOptionsMouseLeave()}>
            {options.map((option) => (
              <div
                className="option-item"
                key={option.id}
                onClick={() => {
                  if (prefix === 'model') {
                    const selectedModel = this.state.brand.models.find(item => item.label === option.label);
                    if (selectedModel && selectedModel.fuelTypes) {
                      this.setState({ fuelType: selectedModel.fuelTypes[0].value });
                    }
                  }
                  this.setState({ [prefix]: option });
                  this.handleClickDropdown(prefix);
                  if (prefix === 'brand') {
                    this.handleBrandChange(option);
                  }
                }}
              >
                <div className="brand-option">
                  { prefix === 'brand' && <img src={require(`../../images/brand_logos/${option.logo}`)} alt={`${option.label} brand logo`} className="brand-image" /> }
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  closeDropdowns = () => {
    Object.keys(this.state).forEach((key) => {
      if (key.endsWith('DropdownOpen')) {
        this.setState({ [key]: false });
      }
    });
  }

  handleDocumentClick = (event) => {
    let targetElement = event.target;
  
    // Check if any parent element contains the class name 'dropdown-label'
    while (targetElement) {
      if (targetElement.classList && targetElement.classList.contains('dropdown-label')) {
        // If the click occurred inside the dropdown label, return early without closing the dropdowns
        return;
      }
      targetElement = targetElement.parentElement;
    }
  
    // Close the dropdowns if the click occurred outside of the dropdown area
    this.closeDropdowns();
  };

  resetModels = () => {
    const carModels = [];
    const bikeModels = [];

    vehicleData.carBrands.forEach((carBrand) => {
      carBrand.models.forEach((carModel) => {
        carModels.push(carModel);
      });
    });

    vehicleData.bikeBrands.forEach((bikeBrand) => {
      bikeBrand.models.forEach((bikeModel) => {
        bikeModels.push(bikeModel);
      });
    });

    carModels.sort((a, b) => a.label.localeCompare(b.label));
    bikeModels.sort((a, b) => a.label.localeCompare(b.label));

    this.setState({ carModels, bikeModels });
  }

  async componentDidMount() {
    axios.get("https://ridenrepair.com/api/get_user_vehicles",
      { params: 
        { 
          phone: this.props.user.ph
        } 
      })
    .then(response => {
      if (response.status === 200 && response.data.vehicles.length > 0) {
        const vehicles = response.data.vehicles;

        if (vehicles.length > 0) {
          this.setState({ userVehicles: vehicles, selectedVehicleIndex: 0 }, () => {
            this.setSelectedVehicle();
          });
        }
      }
    })
    .catch(error => {
    });
    if (typeof document !== 'undefined') {
      document.addEventListener('click', this.handleDocumentClick);
    }
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
        this.props.setOpenModal(true);
      }
    }
    this.resetModels();

    const riderExists = await this.checkRider(this.props.user.ph);

    if (riderExists) {
      bikePrices["0-199cc"][0]["price"] = 699;
      bikePrices["0-199cc"][1]["price"] = 1099;
      bikePrices["0-199cc"][2]["price"] = 400;
      bikePrices["0-199cc"][3]["price"] = 400;
      bikePrices["0-199cc"][4]["price"] = 400;
      bikePrices["200-249cc"][0]["price"] = 749;
      bikePrices["200-249cc"][1]["price"] = 1349;
      bikePrices["200-249cc"][2]["price"] = 400;
      bikePrices["200-249cc"][3]["price"] = 400;
      bikePrices["200-249cc"][4]["price"] = 400;
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  findBrandLogo = (brandName, vehicleType) => {
    let brandLogo = '';
    if (vehicleType === 'car') {
      const carBrand = vehicleData.carBrands.find(brand => brand.label === brandName);
      if (carBrand) {
        brandLogo = carBrand.logo;
      }
    } else if (vehicleType === 'bike') {
      const bikeBrand = vehicleData.bikeBrands.find(brand => brand.label === brandName);
      if (bikeBrand) {
        brandLogo = bikeBrand.logo;
      }
    }
    return brandLogo;
  }

  findBrandData = (brandName, vehicleType) => {
    // Assuming vehicleData structure like: { carBrands: [], bikeBrands: [] }
    const brands = vehicleType === 'car' ? vehicleData.carBrands : vehicleData.bikeBrands;
    return brands.find(brand => brand.label === brandName);
  }

  setSelectedVehicle = () => {
    const { userVehicles, selectedVehicleIndex } = this.state;
    const vehicle = userVehicles[selectedVehicleIndex];
    if (vehicle === '') {
      // Reset dropdowns for a new vehicle
      this.setState({
        vehicleType: 'bike',
        brand: '',
        model: '',
        fuelType: '',
        transmission: 'manual',
        ccRange: '',
      });
      this.resetModels();
    } else {
      // Set selected user vehicle
      this.setState({ vehicleType: vehicle.vehicleType });
      // Find and store entire brand data
      const brandData = this.findBrandData(vehicle.brand, vehicle.vehicleType);
      this.setState({ brand: brandData });

      // Find and store entire model data
      const modelData = brandData ? brandData.models.find(model => model.label === vehicle.model) : null;
      this.setState({ model: modelData ? modelData : null });

      if (vehicle.vehicleType === 'car') {
        this.setState({ fuelType: vehicle.fuelType });
        this.setState({ transmission: vehicle.transmission });
      } else if (vehicle.vehicleType === 'bike') {
        this.setState({ ccRange: vehicle.ccRange });
      }
    }
  }

  handleVehicleChange = (event) => {
    const selectedVehicleIndex = event.target.value;
    if (selectedVehicleIndex === "") {
        // Reset the vehicle selection if the default option is chosen
        this.setState({ selectedVehicleIndex: '' });
        this.setState({
          vehicleType: 'bike',
          brand: '',
          model: '',
          fuelType: '',
          transmission: 'manual',
          ccRange: '',
        });
        this.resetModels();
    } else {
      // Set the selected vehicle based on the option chosen from the dropdown menu
      this.setState({ selectedVehicleIndex }, () => {
          this.setSelectedVehicle();
      });
    }
  };

  // Custom sorting function to prioritize top 10 brands
  customSortByPopularity = (brandArray, top10List) => {
	  return brandArray.sort((a, b) => {
      // Check if either of the brands is in the top 10 list
      const aIndex = top10List.indexOf(a.label);
      const bIndex = top10List.indexOf(b.label);

      // If both brands are in the top 10 list, sort by their index in the top 10 list
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }

      // If one brand is in the top 10 list, prioritize it
      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;

      // Otherwise, sort alphabetically
      return a.label.localeCompare(b.label);
    });
  };

  render() {
    const { vehicleType, brand, model, fuelType, transmission, ccRange, bikeModels, carModels, userVehicles, selectedVehicleIndex } = this.state;
    const { isMobileBrowser, isMobileApp, citiesModal, setCitiesModal, openModal, setOpenModal, user, setUser } = this.props;

    const canonicalUrl = 'https://www.ridenrepair.com/service';

    const top10CarBrands = [
      'Maruti Suzuki', 'Hyundai', 'Tata', 'Mahindra', 
      'Kia', 'Toyota', 'Honda', 'Renault', 'Volkswagen', 'Skoda'
    ];
    
    const top10BikeBrands = [
      'Hero', 'Honda', 'TVS', 
      'Bajaj', 'Royal Enfield', 'Yamaha', 'Suzuki', 
      'KTM', 'Jawa', 'BMW'
    ];

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>Service Checklist - Ride N Repair</title>
          <meta name="description" content="Explore frequently asked questions about Ride N Repair. Find answers related to our services, booking process, pricing, and more. Your questions, answered!" />
          <meta name="keywords" content="FAQ, Ride N Repair, Frequently Asked Questions, Services, Book Service, Doorstep Service, Bike Service, Two-wheeler Service, Vehicle Service" />
        </Helmet>

        {citiesModal && <AvailableCitiesModal isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} closeModal={() => setCitiesModal(false)} />}

        {!isMobileApp && openModal && <Modal isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} openModal={openModal} setOpenModal={setOpenModal} user={user} setUser={setUser} />}

        <Header isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} user={user} setUser={setUser} />

        <div className="page-content bg-white">

          <div className="page-banner ovbl-dark vehicle-services-header" style={{ backgroundColor: 'black', overflow: 'visible' }}>
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1><span>Services</span></h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Services</li>
                  </ul>
                </nav>

                <div>
                  {userVehicles.length > 0 ? (
                    // Only for users have mobile apps and profile available
                    <div className="vehicle-dropdown">
                      <select value={selectedVehicleIndex} onChange={this.handleVehicleChange}>
                        <option value="">Select your vehicle</option>
                        {/* Populate options based on userVehicles */}
                        {userVehicles.map((vehicle, index) => (
                          <option key={index} value={index}>
                            {`${vehicle.model}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <h3 style={{ marginTop: '3%', color: 'white' }}>Choose Your Vehicle</h3>
                  )}
                </div>

                <div className="vehicle-selection-row">
                  <div className="vertical-dropdown-container">
                    <div className="vehicle-type-buttons specific-vehicle-type">
                      <button
                        className={`vehicle-type-button ${vehicleType === 'car' ? 'active' : ''}`}
                        onClick={() => this.handleVehicleTypeChange('car')}
                      >
                        Car
                      </button>
                      <button
                        className={`vehicle-type-button ${vehicleType === 'bike' ? 'active' : ''}`}
                        onClick={() => this.handleVehicleTypeChange('bike')}
                      >
                        Bike
                      </button>
                    </div>

										<div className="vehicle-dropdowns">
											{vehicleType === 'car' && this.renderDropdown(
												// vehicleData.carBrands.sort((a, b) => a.label.localeCompare(b.label)),
                        this.customSortByPopularity(vehicleData.carBrands, top10CarBrands),
												brand,
												'Brand',
												'brand'
											)}

											{vehicleType === 'car' && brand && this.renderDropdown(
												carModels,
												model,
												'Model',
												'model'
											)}

                      {vehicleType === 'car' && model && model.fuelTypes && model.fuelTypes.length > 0 &&
                          <div className="vehicle-type-buttons">
                              {model.fuelTypes.map(fuelTypeData => (
                                  <button
                                      key={fuelTypeData.id}
                                      className={`vehicle-type-button ${fuelType === fuelTypeData.value ? 'active' : ''}`}
                                      onClick={() => this.handleFuelTypeChange(fuelTypeData.value)}
                                  >
                                      {fuelTypeData.label}
                                  </button>
                              ))}
                          </div>
                      }

											{vehicleType === 'car' && 
                        <div className="vehicle-type-buttons">
                          <button
                            className={`vehicle-type-button ${transmission === 'manual' ? 'active' : ''}`}
                            onClick={() => this.handleTransmissionChange('manual')}
                          >
                            Manual
                          </button>
                          <button
                            className={`vehicle-type-button ${transmission === 'automatic' ? 'active' : ''}`}
                            onClick={() => this.handleTransmissionChange('automatic')}
                          >
                            Automatic
                          </button>
                        </div>
                      }

											{vehicleType === 'bike' && this.renderDropdown(
												// vehicleData.bikeBrands.sort((a, b) => a.label.localeCompare(b.label)),
                        this.customSortByPopularity(vehicleData.bikeBrands, top10BikeBrands),
												brand,
												'Brand',
												'brand'
											)}

											{vehicleType === 'bike' && brand && this.renderDropdown(
												bikeModels,
												model,
												'Model',
												'model'
											)}

											{vehicleType === 'bike' && brand && model &&
                        <div className="vehicle-type-buttons cc-range-buttons">
                          <div className="cc-range-button-group">
                            <button
                              className={`vehicle-type-button cc-range-button ${ccRange === '0-199cc' ? 'active' : ''}`}
                              onClick={() => this.handleCCRangeChange('0-199cc')}
                            >
                              0 to 199 cc
                            </button>
                            <button
                              className={`vehicle-type-button cc-range-button ${ccRange === '200-249cc' ? 'active' : ''}`}
                              onClick={() => this.handleCCRangeChange('200-249cc')}
                            >
                              200 to 249 cc
                            </button>
                            <button
                              className={`vehicle-type-button cc-range-button ${ccRange === '250-400cc' ? 'active' : ''}`}
                              onClick={() => this.handleCCRangeChange('250-400cc')}
                            >
                              250 to 400 cc
                            </button>
                          </div>
                          <div className="cc-range-button-group">
                            <button
                              className={`vehicle-type-button cc-range-button ${ccRange === '401-500cc' ? 'active' : ''}`}
                              onClick={() => this.handleCCRangeChange('401-500cc')}
                            >
                              401 to 500 cc
                            </button>
                            <button
                              className={`vehicle-type-button cc-range-button ${ccRange === '501-800cc' ? 'active' : ''}`}
                              onClick={() => this.handleCCRangeChange('501-800cc')}
                            >
                              501 to 800 cc
                            </button>
                            <button
                              className={`vehicle-type-button cc-range-button ${ccRange === '801cc+' ? 'active' : ''}`}
                              onClick={() => this.handleCCRangeChange('801cc+')}
                            >
                              801 cc and above
                            </button>
                          </div>
                        </div>
                      }

										</div>

                  </div>
                  {/* Dynamic vehicle image based on dropdown selection */}
                  {model && model.imagePath && (
                    <div className="vehicle-image-container">
                      <img
                        src={require(`../../images/vehicle_images/${model.imagePath}`)}
                        alt={`${vehicleType} ${model.label}`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {vehicleType === 'car' && this.shouldDisplayServices() &&
            <ServicesSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} vehicleType={vehicleType} model={model} brand={brand} fuelType={fuelType} transmission={transmission} ccRange={ccRange} servicesData={this.getSelectedFuelTypeData()} user={this.props.user} setUser={this.props.setUser} />
          }

          {vehicleType === 'bike' && this.shouldDisplayServicesBike() && 
            <ServicesSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} vehicleType={vehicleType} model={model} brand={brand} fuelType={fuelType} transmission={transmission} ccRange={ccRange} servicesData={this.getSelectedCCData()} user={this.props.user} setUser={this.props.setUser} />
          }

          <ChooseUsSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} />

        </div>

        <Footer isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} setCitiesModal={this.props.setCitiesModal} />

      </>
    );
  }
}

export default Services;
