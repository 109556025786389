import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/bnr1.jpg";

// import { blogsContent } from '../elements/blogs-data';
import { Helmet } from 'react-helmet';

import BlogGridPaginated from './blog-grid-paginated';

import Modal from '../elements/modal';

class BlogListSidebar extends Component{
		constructor(props) {
			super(props);
			
			this.createSlug = this.createSlug.bind(this);
		}

	componentDidMount() {
		if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
			this.props.setOpenModal(true);
		}
	}

	
	createSlug = (title) => 
		title
			.toLowerCase()
			.replace(/[\u2013\u2014]+/g, '')        // Remove any em dash or en dash
			.replace(/[^a-z0-9\s-]/g, '')           // Remove other special characters, allow hyphens
			.replace(/\s+/g, '-')                   // Replace spaces with a single hyphen
			.replace(/-+/g, '-')                    // Collapse multiple hyphens into one
			.replace(/^-|-$/g, '');                 // Remove leading or trailing hyphens	

	render(){

		const { isMobileBrowser, isMobileApp, citiesModal, setCitiesModal, openModal, setOpenModal, user, setUser } = this.props;
		const canonicalUrl = 'https://www.ridenrepair.com/blogs';

		return (
			<>
				<Helmet>
					<link rel="canonical" href={canonicalUrl} />
					<title>Our Blogs - Ride N Repair</title>
					<meta
						name="description"
						content="Explore insightful blogs from Ride N Repair covering essential details, accessories, and myths about bikes. Get informed and ride safely!"
					/>
					<meta
						name="keywords"
						content="Ride N Repair, motorcycle, bike accessories, bike myths, bike purchase, bike details, bike service"
					/>
				</Helmet>
				
				{citiesModal && <AvailableCitiesModal isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} closeModal={() => this.props.setCitiesModal(false)} />}

				{!isMobileApp && openModal && <Modal isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} openModal={openModal} setOpenModal={setOpenModal} user={user} setUser={setUser} />}
				
				<Header isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} user={user} setUser={setUser} />
				
				<div className="page-content bg-gray">
					
				<div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bnrImg})` }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Blogs</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Blogs</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>

					<BlogGridPaginated blogsData={this.props.blogsData} postsPerPage={6} />
					
				</div>
				
				<Footer isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} setCitiesModal={setCitiesModal} />
				
			</>
		);
	}
}

export default BlogListSidebar;