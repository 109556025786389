import React, { Component } from 'react';
import { cities } from './cities-data';
import vehicleData from '../../data/vehicle_data.json';
import '../../css/partnerships.css';

class PartnerShipsSection extends Component {
  render() {
    const { cityKey = '', areaKey = '', cityContent = {} } = this.props;

    // Capitalize each word in cityKey, e.g. "new-delhi" => "New Delhi"
    const formattedCityKey = cityKey
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    // ========== BRAND DATA: combine, deduplicate, sort ==========
    const allBrands = [
      ...new Set([
        ...vehicleData.carBrands.map((brand) => brand.label),
        ...vehicleData.bikeBrands.map((brand) => brand.label),
      ]),
    ];
    const sortedAllBrands = allBrands.sort((a, b) => a.localeCompare(b));

    // Render brand items as clickable anchors (no href for now)
    const renderBrandItems = sortedAllBrands.map((brand, index) => (
      <a key={index} style={{ textDecoration: 'none' }}>
        {brand}
      </a>
    ));

    // ========== DECIDE WHETHER TO SHOW AREAS OR CITIES ==========
    let areaOrCityTitle = 'Cities We Serve:';
    let areaOrCityItems = [];

    // Check if cityKey is valid and we have an areas array
    if (cityKey && cityContent[cityKey] && cityContent[cityKey].areas) {
      areaOrCityTitle = `Areas We Serve in ${formattedCityKey}:`;

      // Sort areas by label
      const sortedAreas = [...cityContent[cityKey].areas].sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      // Build clickable links to "https://www.ridenrepair.com/<cityKey>/<areaValue>"
      areaOrCityItems = sortedAreas.map((areaObj, idx) => (
        <a
          key={idx}
          style={{ textDecoration: 'underline', color: '#f42222' }}
          href={`https://www.ridenrepair.com/city/${cityKey}/${areaObj.value}`}
        >
          {areaObj.label}
        </a>
      ));
    } else {
      // Otherwise, show all cities
      const sortedCities = [...cities].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Build clickable links to "https://www.ridenrepair.com/<cityName>"
      areaOrCityItems = sortedCities.map((city, index) => (
        <a
          key={index}
          style={{ textDecoration: 'underline', color: '#f42222' }}
          href={`https://www.ridenrepair.com/city/${city.name.toLowerCase()}`}
        >
          {city.name}
        </a>
      ));
    }

    // ========== CHUNKING FOR COLUMNS ==========
    const chunkArray = (array, size) => {
      const chunkedArr = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
      }
      return chunkedArr;
    };

    // Split area/city items into columns of 6 each
    const chunkSizeForAreasOrCities = (cityKey && cityContent[cityKey]?.areas)
    ? 15
    : 6;

    const chunkedAreaOrCityItems = chunkArray(areaOrCityItems, chunkSizeForAreasOrCities);

    // Split brand items into columns of 13 each
    const chunkedBrandItems = chunkArray(renderBrandItems, 13);

    return (
      <section className="section-area section-sp1 bg-white">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-5 col-lg-5 text-center">
            <div className="heading-bx mb-4 pr-lg-5">
              <h2 className="title">OUR NETWORK</h2>
              <p>
                We have established partnerships with trusted brands and serve a
                variety of locations across the country.
              </p>

              {/* Areas (if cityKey) or Cities (no cityKey) */}
              <div>
                <h4>{areaOrCityTitle}</h4>
                <div className="partners-grid">
                  {chunkedAreaOrCityItems.map((column, columnIndex) => (
                    <ul key={columnIndex} className="partners-column">
                      {column.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>

              {/* Brands */}
              <div style={{ marginTop: '30px' }}>
                <h4>Brands We Serve:</h4>
                <div className="partners-grid">
                  {chunkedBrandItems.map((column, columnIndex) => (
                    <ul key={columnIndex} className="partners-column">
                      {column.map((brandItem, brandIndex) => (
                        <li key={brandIndex}>{brandItem}</li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PartnerShipsSection;
